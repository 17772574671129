import { FunctionComponent, ReactNode } from 'react';

interface HeadingWithIconProps {
  icon: ReactNode;
  text?: string;
  children?: ReactNode;
  className?: string;
}

const TextWithIcon: FunctionComponent<HeadingWithIconProps> = (props) => {
  return (
    <div className={`flex flex-row items-center  ${props.className}`}>
      <div className="mr-2">{props.icon}</div>
      <div className="">{props.children || props.text}</div>
    </div>
  );
};

export default TextWithIcon;

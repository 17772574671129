import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { CalendarEvent } from '../../lib/api/types/calendar-event';
import { CalendarEventFilter } from '../../lib/api/types/calendar-event-filter';
import AppContext, { defaultAppContext } from './AppContext';

interface Props {
  children: ReactNode;
}

const AppGlobalState: FunctionComponent<Props> = (props) => {
  const [calendarEventFilter, setCalendarEventFilter] =
    useState<CalendarEventFilter>(defaultAppContext.calendarEventFilter);

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);
  const [desktopFiltersOpen, setDesktopFiltersOpen] = useState(false);
  const [filterIsActive, setFilterIsActive] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [filteredEvents, setFilteredEvents] = useState<CalendarEvent[]>([]);

  useEffect(() => {
    setFilterIsActive(
      JSON.stringify(defaultAppContext.calendarEventFilter) !==
        JSON.stringify(calendarEventFilter)
    );
  }, [calendarEventFilter]);

  const resetCalendarEventFilter = () =>
    setCalendarEventFilter(defaultAppContext.calendarEventFilter);

  return (
    <AppContext.Provider
      value={{
        calendarEventFilter,
        setCalendarEventFilter,
        resetCalendarEventFilter,
        filterIsActive,
        mobileFiltersOpen,
        setMobileFiltersOpen,
        desktopFiltersOpen,
        setDesktopFiltersOpen,
        showBanner,
        setShowBanner,
        filteredEvents,
        setFilteredEvents,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppGlobalState;

import {
  LocationMarkerIcon,
  InformationCircleIcon,
  CreditCardIcon,
  PhoneIcon,
  MailIcon,
} from '@heroicons/react/solid';
import { FunctionComponent } from 'react';
import HeadingWithIcon from '../shared/HeadingWithIcon';
import TextWithIcon from '../shared/TextWithIcon';

const Footer: FunctionComponent = () => {
  return (
    <footer className="mt-8 text-gray-800">
      <hr className="border-t-4 border-primary" />
      <div className="mx-auto max-w-md my-5 max-w-md md:items-start md:flex md:flex-row md:justify-center md:max-w-full">
        <HeadingWithIcon
          headingText="TAGEO Geschäftsstelle"
          icon={<LocationMarkerIcon className="h-8 w-8 text-primary" />}
        >
          <address>
            Gabriela Daxboeck
            <br />
            Dachsweg 7<br />
            8500 Frauenfeld
          </address>
          <TextWithIcon
            className="mb-2 mt-2"
            icon={<PhoneIcon className="h-5 w-5 text-primary" />}
          >
            <a href="tel:0041791573604">079 157 36 04</a>
          </TextWithIcon>
          <TextWithIcon
            className="mb-4"
            text="geschaeftsstelle@tageo.ch"
            icon={<MailIcon className="h-5 w-5 text-primary" />}
          >
            <a href="mailto:geschaeftsstelle@tageo.ch">
              geschaeftsstelle@tageo.ch
            </a>
          </TextWithIcon>
        </HeadingWithIcon>

        <HeadingWithIcon
          headingText="Information"
          icon={<InformationCircleIcon className="h-8 w-8 text-primary" />}
        >
          <div className="my-2">
            <a
              href="https://tageo.ch/impressum/"
              target={'_blank'}
              rel="noreferrer"
              className="text-primary underline"
            >
              Impressum
            </a>
          </div>
          <div className="my-2">
            <a
              href="https://tageo.ch/datenschutz/"
              target="_blank"
              rel="noreferrer"
              className="text-primary underline"
            >
              Datenschutz
            </a>
          </div>
        </HeadingWithIcon>

        <HeadingWithIcon
          headingText="Spenden"
          icon={<CreditCardIcon className="h-8 w-8 text-primary" />}
        >
          CH48 8080 8009 5710 4841 0<br />
          zu Gunsten TAGEO
          <br />
          Raiffeisenbank Mittelthurgau
          <br />
          8570 Weinfelden
        </HeadingWithIcon>
      </div>

      <div className="max-w-full mx-auto max-w-md text-center text-white bg-primary pb-2 p-1 lg:p-1">
        Copyright &copy; {new Date().getFullYear()} TAGEO - All Rights Reserved.
        | Powered by{' '}
        <a href="https://beebase.ch/" target="_blank" rel="noreferrer">
          BeeBase
        </a>
      </div>
    </footer>
  );
};

export default Footer;

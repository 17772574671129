enum MEDIA {
  DESKTOP,
  MOBILE,
}

export const handleActiveRouteDesktop = (
  path: string,
  routerPathname: string
): string => {
  return handleActiveRoute(path, routerPathname, MEDIA.DESKTOP);
};

export const handleActiveRouteMobile = (
  path: string,
  routerPathname: string
): string => {
  return handleActiveRoute(path, routerPathname, MEDIA.MOBILE);
};

const handleActiveRoute = (
  path: string,
  routerPathname: string,
  media: MEDIA
): string => {
  if (media === MEDIA.DESKTOP) {
    return path === routerPathname
      ? 'link-active-desktop'
      : 'link-inactive-desktop';
  }

  if (media === MEDIA.MOBILE) {
    return path === routerPathname
      ? 'link-active-mobile'
      : 'link-inactive-mobile';
  }

  return '';
};

import Head from 'next/head';
import { FunctionComponent } from 'react';

const MetaData: FunctionComponent = () => {
  const appName = 'TAGEO Kalender';
  const appTitle = 'TAGEO Elternbildungskalender';
  const appUrl = 'https://kalender.tageo.ch/';
  const appDescription =
    'Hier finden Sie die aktuellsten Elternbildungsveranstaltungen im Kanton Thurgau. Die Inserate werden laufend ergänzt, es lohnt sich immer wieder einmal vorbei zu schauen.';
  return (
    <Head>
      <link rel="manifest" href="/manifest.json" />
      <meta name="theme-color" content="#f39200" />
      <title>{appTitle}</title>
      <meta name="description" content={appDescription} />
      <meta
        name="viewport"
        content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, viewport-fit=cover"
      />
      <link rel="icon" href="/favicon.ico" />
      <meta name="application-name" content={appName} />

      {/* Twitter Metadata */}
      <meta name="twitter:card" content={appDescription} />
      <meta name="twitter:url" content={appUrl} />
      <meta name="twitter:title" content={appTitle} />
      <meta name="twitter:description" content={appDescription} />
      <meta name="twitter:image" content="/icon-256x256.png" />
      <meta name="twitter:creator" content="TAGEO" />

      {/* Open Graph Protocol */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={appTitle} />
      <meta property="og:description" content={appDescription} />
      <meta property="og:site_name" content={appName} />
      <meta property="og:url" content={appUrl} />
      <meta property="og:image" content="/icon-256x256.png" />

      {/* Apple Metadata */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content={appName} />
      <link rel="apple-touch-icon" href="/apple-touch-icon.png"></link>
      <link
        href="splashscreens/iphone5_splash.png"
        media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/iphone6_splash.png"
        media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/iphoneplus_splash.png"
        media="(device-width: 621px) and (device-height: 1104px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/iphonex_splash.png"
        media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/iphonexr_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/iphonexsmax_splash.png"
        media="(device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/ipad_splash.png"
        media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/ipadpro1_splash.png"
        media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/ipadpro3_splash.png"
        media="(device-width: 834px) and (device-height: 1194px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
      <link
        href="splashscreens/ipadpro2_splash.png"
        media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
        rel="apple-touch-startup-image"
      />
    </Head>
  );
};

export default MetaData;

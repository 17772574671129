import { Disclosure } from '@headlessui/react';
import {
  MenuIcon,
  PrinterIcon,
  SearchIcon,
  XIcon,
} from '@heroicons/react/solid';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext } from 'react';
import {
  handleActiveRouteDesktop,
  handleActiveRouteMobile,
} from '../../lib/utility/handle-active-route';
import AppContext from './AppContext';

const Navigation: FunctionComponent = () => {
  const router = useRouter();
  const {
    setMobileFiltersOpen,
    setDesktopFiltersOpen,
    desktopFiltersOpen,
    filteredEvents,
  } = useContext(AppContext);

  return (
    <Disclosure as="nav" className="bg-white shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between h-16">
              <div className="flex">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-yellow-500">
                    <span className="sr-only">Hauptmen&uuml; &ouml;ffnen</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-shrink-0 flex items-center">
                  <Image
                    src="/logo.svg"
                    height={34}
                    width={35}
                    alt="TAEGO Logo"
                    onClick={() => window.scrollTo(0, 0)}
                  />
                </div>
                <div className="hidden md:ml-6 md:flex md:space-x-4 lg:space-x-8">
                  <div
                    className={handleActiveRouteDesktop('/', router.pathname)}
                  >
                    <Link href="/">Kurse und Einzel&shy;veranstaltungen</Link>
                  </div>
                  <div
                    className={handleActiveRouteDesktop(
                      '/wiederkehrende',
                      router.pathname
                    )}
                  >
                    <Link href="/wiederkehrende">
                      Wieder&shy;kehrende Veranstaltungen
                    </Link>
                  </div>
                  <div
                    className={handleActiveRouteDesktop(
                      '/fremdsprachige',
                      router.pathname
                    )}
                  >
                    <Link href="/fremdsprachige">
                      Angebote für Fremdsprachige
                    </Link>
                  </div>
                  <div
                    className={handleActiveRouteDesktop(
                      '/veranstalter',
                      router.pathname
                    )}
                  >
                    <Link href="/veranstalter">Angebote für Veranstalter</Link>
                  </div>
                  <div
                    className={handleActiveRouteDesktop(
                      '/links',
                      router.pathname
                    )}
                  >
                    <Link href="https://tageo.ch/information-beratung/">
                      <a target="_blank">Weitere Angebote (Extern)</a>
                    </Link>
                  </div>
                </div>
              </div>

              <div className="flex items-center lg:flex hidden">
                <Link href="/pdf">
                  <a className="relative inline-flex items-center py-2 mx-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                    <PrinterIcon className="h-5 w-5 mx-3" />
                  </a>
                </Link>
              </div>

              <div
                className={`flex items-center lg:hidden ${
                  router.pathname.includes('veranstaltungen')
                    ? 'opacity-50'
                    : ''
                }`}
              >
                <div className="flex-shrink-0">
                  <button
                    aria-label="Filter"
                    disabled={router.pathname.includes('veranstaltungen')}
                    onClick={() => setMobileFiltersOpen(true)}
                    type="button"
                    className="lg:hidden relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    <SearchIcon
                      className="-ml-1 mr-2 h-5 w-5"
                      aria-hidden="true"
                    />
                    <span>Filter</span>
                  </button>
                </div>
              </div>
              <div
                className={`flex items-center lg:flex hidden ${
                  router.pathname.includes('veranstaltungen')
                    ? 'opacity-50'
                    : ''
                }`}
              >
                <div className="flex-shrink-0">
                  <button
                    disabled={router.pathname.includes('veranstaltungen')}
                    onClick={() => setDesktopFiltersOpen(!desktopFiltersOpen)}
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-primary shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                  >
                    {desktopFiltersOpen ? (
                      <XIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <SearchIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    <span>Filter</span>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="md:hidden">
            <div className="pt-2 pb-3 space-y-1">
              <Disclosure.Button
                className={handleActiveRouteMobile('/', router.pathname)}
              >
                <Link href="/">Kurse und Einzelveranstaltungen</Link>
              </Disclosure.Button>
              <Disclosure.Button
                className={handleActiveRouteMobile(
                  '/wiederkehrende',
                  router.pathname
                )}
              >
                <Link href="/wiederkehrende">
                  Wiederkehrende Veranstaltungen
                </Link>
              </Disclosure.Button>
              <Disclosure.Button
                className={handleActiveRouteMobile(
                  '/fremdsprachige',
                  router.pathname
                )}
              >
                <Link href="/fremdsprachige">Angebote für Fremdsprachige</Link>
              </Disclosure.Button>
              <Disclosure.Button
                className={handleActiveRouteMobile(
                  '/veranstalter',
                  router.pathname
                )}
              >
                <Link href="/veranstalter">Angebote für Veranstalter</Link>
              </Disclosure.Button>
              <Disclosure.Button
                className={handleActiveRouteMobile('/links', router.pathname)}
              >
                <Link href="https://tageo.ch/information-beratung/">
                  <a target="_blank">Weitere Angebote (Extern)</a>
                </Link>
              </Disclosure.Button>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Navigation;

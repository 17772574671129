import Link from 'next/link';
import { VFC } from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieConsentBanner: VFC = (props) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="OK"
      style={{
        background: '#f39200',
        'boxShadow': '0px 0px 15px 0px rgba(0, 0, 0, .2)',
      }}
      buttonStyle={{
        backgroundColor: '#fff',
        color: '#f39200',
      }}
    >
      Diese Website verwendet Cookies, um Ihnen den bestmöglichen Service zu
      bieten. Mit dem Verbleiben auf dieser Website erklären Sie sich mit
      unseren{' '}
      <Link href="https://tageo.ch/datenschutz/">
        <a target="_blank" className="underline">
          Datenschutzrichtlinien
        </a>
      </Link>{' '}
      einverstanden.
    </CookieConsent>
  );
};

export default CookieConsentBanner;

import { createContext } from 'react';
import { CalendarEvent } from '../../lib/api/types/calendar-event';
import { CalendarEventFilter } from '../../lib/api/types/calendar-event-filter';
import { DEFAULT_AGE_RANGE_OPTIONS } from '../../lib/utility/filter-default-values';

interface AppContext {
  calendarEventFilter: CalendarEventFilter;
  setCalendarEventFilter: (calendarEventFilter: CalendarEventFilter) => void;
  resetCalendarEventFilter: () => void;
  filterIsActive: boolean;
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (open: boolean) => void;
  desktopFiltersOpen: boolean;
  setDesktopFiltersOpen: (open: boolean) => void;
  showBanner: boolean;
  setShowBanner: (show: boolean) => void;
  filteredEvents: CalendarEvent[];
  setFilteredEvents: (calendarEvents: CalendarEvent[]) => void;
}

const defaultCalendarEventFilter: CalendarEventFilter = {
  textSearchFilter: '',
  ageRangeFilter: DEFAULT_AGE_RANGE_OPTIONS,
  startDateFilter: null,
  endDateFilter: null,
  region: '',
};

export const defaultAppContext: AppContext = {
  calendarEventFilter: defaultCalendarEventFilter,
  setCalendarEventFilter: () => {},
  resetCalendarEventFilter: () => {},
  filterIsActive: false,
  mobileFiltersOpen: false,
  setMobileFiltersOpen: () => {},
  desktopFiltersOpen: false,
  setDesktopFiltersOpen: () => {},
  showBanner: false,
  setShowBanner: () => {},
  filteredEvents: [],
  setFilteredEvents: () => {},
};

const AppContext = createContext<AppContext>(defaultAppContext);

export default AppContext;

import type { AppProps } from 'next/app';
import Script from 'next/script';
import 'tailwindcss/tailwind.css';
import AppGlobalState from '../components/common/AppGlobalState';
import Layout from '../components/layout';
import CookieConsentBanner from '../components/shared/CookieConsentBanner';
import '../styles/globals.css';

function MyApp({ Component, pageProps, router }: AppProps) {
  return (
    <AppGlobalState>
      <Layout router={router}>
        <Component {...pageProps} />
        <CookieConsentBanner />
      </Layout>

    </AppGlobalState>
  );
}

export default MyApp;

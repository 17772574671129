import { FunctionComponent, ReactNode } from 'react';

interface HeadingWithIconProps {
  icon: ReactNode;
  headingText: string;
  children?: ReactNode;
}

const HeadingWithIcon: FunctionComponent<HeadingWithIconProps> = (props) => {
  return (
    <div className="flex flex-row mx-3 my-5 p-1">
      <div className="mr-2">{props.icon}</div>
      <div className="flex flex-col">
        <div className="text-xl font-semibold mb-1">{props.headingText}</div>
        <div className="">{props.children}</div>
      </div>
    </div>
  );
};

export default HeadingWithIcon;

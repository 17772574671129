export const DEFAULT_AGE_RANGE_OPTIONS = [
  'Zyklus 0 (0-4 Jahre)',
  'Zyklus 1 (4-7 Jahre)',
  'Zyklus 2 (8-11 Jahre)',
  'Zyklus 3 (12-14 Jahre)',
  'Zyklus 4 (15-18 Jahre)',
];

export const DEFAULT_REGION_OPTIONS = [
  'Arbon',
  'Frauenfeld',
  'Kreuzlingen',
  'Münchwilen',
  'Weinfelden',
];

import { FunctionComponent, ReactNode } from 'react';
import Footer from './common/Footer';
import Header from './common/Header';
import MetaData from './common/MetaData';
import { Router } from 'next/router';
import { motion } from 'framer-motion';

interface Props {
  router: Router;
  children: ReactNode;
}

const Layout: FunctionComponent<Props> = ({ children, router }) => {
  if (router.pathname === '/pdf') {
    return <main className="flex justify-center">{children}</main>;
  }

  return (
    <div>
      <MetaData />
      <div className="bg-gray-50">
        <div className="sticky top-0 z-10">
          <Header />
        </div>
        <motion.div
          key={router.route}
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 100 }}
          transition={{
            type: 'spring',
            mass: 0.1,
            stiffness: 100,
            duration: 0.05,
          }}
        >
          <main className="flex justify-center">{children}</main>
        </motion.div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
